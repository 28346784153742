<template>
    <div class="Meeting" v-if="Title">
        <div style="height: 65px;line-height: 65px" class="warp-padding flex">
            <div>
                <router-link to="/meeting"
                >
                    <a-icon
                            class="return"
                            type="left"
                    />&nbsp;&nbsp;修改学习计划
                </router-link
                >
            </div>
            <div>
                <router-link to="/meeting">
                    <a-button class="release">取消</a-button>
                </router-link>
                <a-button type="primary" @click="submitInfo" class="release"
                >发布
                </a-button
                >
            </div>
        </div>
        <a-row :gutter="19">
            <!--            左边-->
            <a-col :span="16">
                <div style="background: #ffffff" class="warp-padding flex">
                    <div class="coverImg">
                        <div class="cover-upload">
                            <a-button style="position: relative">
                                {{ Photo ? '编辑封面图' : '设置封面图' }}
                                <input
                                        accept=".jpg,.png,.gif,.jpeg"
                                        type="file"
                                        class="upload-bth"
                                        id="Photo"
                                        @change="_upload($event, 'Photo')"
                                />
                            </a-button>
                        </div>
                        <img :src="Photo + '?imageView2/1/w/520/h/292'" alt=""/>
                    </div>

                    <div class="coverRight">
                        <a-input
                                placeholder="请输入标题"
                                v-model="Title"
                                class="line-margin"
                        />
                        <div class="flex line-margin">
                            <span class="person-text">负责人</span>
                            <a-avatar
                                    class="person-avatar"
                                    :size="42"
                                    icon="user"
                                    shape="circle"
                                    :src="PersonInChargeImg ? PersonInChargeImg : ''"
                            />
                            <person
                                    v-bind="personObj"
                                    style="flex:1"
                                    @getData="ChangeUser"
                            ></person>
                        </div>
                    </div>
                </div>
                <a-row :gutter="19" style="margin-bottom: 20px">
                    <a-col :span="10" style="min-width:160px;">
                        <div class="base-warp">
                            <h1 class="base-text">基本信息</h1>
                            <div class="flex group" style="justify-content: start;">
                                <span class="label-tips">创建人</span>
                                <span class="">{{ createUser }}</span>
                            </div>
                            <div class="flex group">
                                <span class="group-label">发布时间</span>
                                <!--                                    -->
                                <a-range-picker
                                        v-if="StartTime"
                                        :value="[
                    moment(StartTime, dateFormat),
                    moment(EndTime, dateFormat)
                  ]"
                                        show-time
                                        style="flex: 1;"
                                        format="YYYY-MM-DD HH:mm"
                                        :placeholder="['开始时间2', '结束时间2']"
                                        allowClear
                                        @change="ChangeTime"
                                />
                                <a-range-picker
                                        v-else
                                        show-time
                                        style="flex: 1"
                                        format="YYYY-MM-DD "
                                        :placeholder="['开始时间', '结束时间']"
                                        allowClear
                                        @change="ChangeTime"
                                />
                            </div>
                            <div class="flex group" style="display:none">
                                <span class="label-tips">提醒</span>
                                <a-button style="flex: 1;color:#bfbfbf">+添加提醒</a-button>
                            </div>
                            <div class="flex group">
                                <span class="group-label">地点</span>
                                <a-input
                                        v-model="MeetingAddress"
                                        style="flex: 1"
                                        placeholder="请输入地址"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">形式</span>
                                <a-select style="flex: 1" v-model="Form" placeholder="请选择">
                                    <a-select-option value="1">线上</a-select-option>
                                    <a-select-option value="2">线下</a-select-option>
                                    <a-select-option value="3">线上线下</a-select-option>
                                </a-select>
                            </div>
                            <div class="flex group">
                                <span class="group-label">参与方式</span>
                                <a-select
                                        style="flex: 1;margin-right: 5px"
                                        @change="ChangeJoinType"
                                        v-model="JoinType"
                                        placeholder="请选择"
                                >
                                    <a-select-option value="1">邀请</a-select-option>
                                    <a-select-option value="2">报名</a-select-option>
                                </a-select>
                            </div>

                            <div class="flex group">
                <span class="group-label">{{
                  JoinType == 1 ? '邀请人员' : '人数上限'
                }}</span>
                                <div
                                        v-if="JoinType == 1"
                                        style="flex:1;height:32px;overflow: hidden;border: 1px solid #d9d9d9;border-radius: 5px;line-height: 32px"
                                >
                                    <!--<span style="color:rgba(153,153,153,1);font-size: 14px;display:block;">共{{JoinPersoinCount}}人</span>-->
                                    <person
                                            style="border:0;opacity: 0"
                                            v-bind="personjoin"
                                            @getData="JoinUser"
                                    ></person>
                                </div>
                                <a-input
                                        v-model="SignUp"
                                        v-else
                                        placeholder="请输入人数上限"
                                        style="flex:1;"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">学费</span>
                                <a-input
                                        v-model="CostFree"
                                        style="flex: 1"
                                        placeholder="请输入学费"
                                />
                            </div>
                            <div class="flex group">
                                <span class="group-label">课程类型</span>
                                <a-select
                                        v-model="Category"
                                        style="flex: 1"
                                        placeholder="请选择课程类型"
                                >
                                    <a-select-option
                                            v-if="categoryList"
                                            v-for="item in categoryList"
                                            :value="item.Id"
                                    >{{ item.title }}
                                    </a-select-option
                                    >
                                </a-select>

                            </div>
                        </div>
                    </a-col>
                    <a-col :span="14">
                        <div class="base-warp" style="padding: 17px 20px">
                            <div class="flex">
                                <h1 class="base-text">
                                    参与人员
                                    <span style="color:rgba(153,153,153,1);font-size: 14px"
                                    >共{{ JoinPersoin.length }}人</span
                                    >
                                </h1>
                                <!-- <a-button v-if="JoinType==2" type="primary" @click="signup">我要报名</a-button> -->
                                <div>
                                    <a-input
                                            v-model="copyUrl"
                                            ref="copy"
                                            style="position: absolute;opacity: 0;z-index:-1"
                                    />
                                    <a-button style="margin-right: 10px;" @click="_copyUrl"
                                    >复制链接
                                    </a-button
                                    >
                                    <a-button type="primary" style="position: relative">
                                        上传excel人员表
                                        <input
                                                type="file"
                                                class="upload-bth"
                                                id="excel"
                                                @change="excelFn($event, 'Video')"
                                        />
                                    </a-button>
                                </div>
                            </div>

                            <div
                                    class="group"
                                    style="display: inline-block;position: relative"
                                    v-if="JoinPersoin && loadShow"
                                    v-for="(item, index) in JoinPersoin"
                            >
                                <a-avatar
                                        @mouseover="_hoverPerson(index)"
                                        class="person-avatar"
                                        :size="42"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon"
                                />
                                <div class="join-name">{{ item.title }}</div>
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="TutorDel(item.PersonId, index, 2)"
                                        @cancel="maxClassify = -1"
                                        style="cursor: pointer"
                                >
                                    <template slot="title"><p>确定删除么？</p></template>
                                    <i
                                            v-if="PersonDelStatus == index"
                                            style="position: absolute;top: 90%;left: 50%;transform: translateX(-50%);"
                                            class="ant-menu-item-selected iconfont icon-shanchu-"
                                    ></i>
                                </a-popconfirm>
                            </div>
                            <span
                                    style="margin-right:15px;margin-bottom:15px;white-space: nowrap;"
                                    v-if="!loadShow"
                                    v-for="(item, index) in JoinPersoin"
                                    class="join-name"
                            >{{ item.ParticipantName }}</span
                            >
                            <a-icon
                                    type="ellipsis"
                                    v-show="loadShow"
                                    style="fontSize:36px;"
                                    title="加载更多"
                                    @click="loadMoveFn()"
                            />
                        </div>
                    </a-col>
                </a-row>
                <!--                    导师介绍start-->
                <div style="" class="warp-padding">
                    <h1 class="base-text">课程概况</h1>
                    <div class="flex">
                        <h2 class="tutor-text">导师介绍</h2>
                        <a-dropdown>
                            <a-menu slot="overlay" @click="showDrawer">
                                <a-menu-item key="2">外部</a-menu-item>
                                <a-menu-item key="1">内部</a-menu-item>
                            </a-menu>
                            <a-button type="primary">
                                添加导师
                                <a-icon type="down"/>
                            </a-button>
                        </a-dropdown>
                        <!--                            <a-button @click="showDrawer" type="primary">添加导师</a-button>-->
                    </div>

                    <div
                            class="tutor"
                            v-if="tutorList"
                            v-for="(item, index) in tutorList"
                    >
                        <div class="flex">
                            <div style="width: 92px">
                                <a-avatar
                                        class="person-avatar"
                                        :size="92"
                                        icon="user"
                                        shape="circle"
                                        :src="item.icon + '?imageView2/1/w/92/h/92'"
                                />
                            </div>

                            <div class="tutor-info">
                                <div class="tutor-name">
                                    <span style="margin-right:5px">{{ item.title }}</span>
                                    <!--.capture.stop-->
                                    <!--<i-->

                                            <!--@click="SaveTutor(index,$event)"-->
                                            <!--style="margin-right:5px;cursor: pointer"-->
                                            <!--@mouseover="_hoverActive(item.SpeakerTime+item.title+index, 1,$event)"-->
                                            <!--:class="active2 == item.SpeakerTime+item.title+index ? 'ant-menu-item-selected' : ''"-->
                                            <!--class="iconfont icon-bianji"-->
                                            <!--@mouseleave="_hoverHide"-->
                                    <!--&gt;</i>-->

                                    <i
                                            v-if="active2 ==-1"
                                        style="margin-right:5px;cursor: pointer"
                                        @mouseover="_hoverActive(item.SpeakerTime+item.title+index, 1,$event)"

                                        class="iconfont icon-bianji"
                                        @mouseleave="_hoverHide"
                                ></i>

                                <i
                                        v-if="active2 ==item.SpeakerTime+item.title+index"
                                        @click="SaveTutor(index,$event)"
                                        style="margin-right:5px;cursor: pointer"
                                        
                                        class="iconfont icon-bianji ant-menu-item-selected"
                                        @mouseleave="_hoverHide"
                                ></i>

                                    <a-popconfirm
                                            placement="bottom"
                                            cancelText="取消"
                                            okText="确定"
                                            @confirm="TutorDel(item.PersonId, index)"
                                            @cancel="maxClassify = -1"
                                            style="cursor: pointer"
                                    >
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <i
                                                @mouseover="_hoverActive(item.PersonId+index, 2,$event)"
                                                class="iconfont icon-shanchu-"
                                                :class="active == item.PersonId+index ? 'ant-menu-item-selected' : ''"
                                                @mouseleave="_hoverHide"
                                        ></i>
                                    </a-popconfirm>
                                </div>
                                <div class="tutor-base" :title="item.InfoAll">
                                    {{ item.Info }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <h2 class="tutor-text">课程介绍</h2>
                    <a-textarea
                            v-model="Remark"
                            placeholder="请输入课程介绍"
                            :autosize="{ minRows: 6 }"
                    />
                </div>
                <!--                    导师介绍end-->
            </a-col>
            <!--                右边-->
            <a-col :span="8">
                <div class="warp-padding" style="height: 346px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">相关视频</h1>
                        <div>
                            <a-button
                                    style="position: relative;margin-right: 10px;"
                                    @click="_showVideoAlert(1)"
                            >查询数据
                            </a-button
                            >
                            <a-button
                                    type="primary"
                                    style="position: relative"
                                    @click="visibleVideo = true"
                            >
                                上传视频
                                <!-- <input  type="file" class='upload-bth' id="Video" @change="_upload($event,'Video')"> -->
                            </a-button>
                        </div>
                    </div>
                    <div v-show="Video" v-for="(item, index) in Video" class="video">
                        <!--:poster="item.FileUrl+'?vframe/jpg/offset/1'"-->
                        <video controls   controlslist="nodownload" >
                            <source :src="item.FileUrl"/>
                        </video>
                        <div style="display:flex;">
                            <div class="video-title">{{ item.FileName }}</div>
                            <div style="float:right;">
                                <!-- <span class="video-upload-time">上传于{{item.nowTime}}</span> -->
                                <i
                                        @mouseover="_hoverActive(index, 1)"
                                        :class="active2 == index ? 'ant-menu-item-selected' : ''"
                                        class="iconfont icon-bianji"
                                        @mouseleave="_hoverHide"
                                        @click="SaveVideo(index)"
                                        style="margin-right:10px;"
                                ></i>
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="VideoDel(index)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>确定删除么？</p>
                                    </template>
                                    <i
                                            @mouseover="_hoverActive(index, 2)"
                                            class="iconfont icon-shanchu-"
                                            :class="active == index ? 'ant-menu-item-selected' : ''"
                                            @mouseleave="_hoverHide"
                                    ></i>
                                </a-popconfirm>
                            </div>
                        </div>
                        <div class="shade" @click="_selectVideo(item)"></div>
                        <a-icon
                                type="play-circle"
                                @click="_selectVideo(item)"
                                class="play-btn"
                        />
                    </div>
                </div>
                <div class="warp-padding" style="height: 330px;overflow-y: auto">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">活动风采</h1>
                        <a-button v-if="Id" type="primary" style="position: relative">
                            上传图片
                            <input
                                    type="file"
                                    class="upload-bth"
                                    id="ActivityImg"
                                    @change="ActivityImg($event, 'ActivityImg', 1)"
                            />
                        </a-button>
                    </div>
                    <div v-viewer>
                        <div
                                v-if="files"
                                v-for="(item, index) in files"
                                class="imsges"
                                style="position: relative"
                        >
                            <img :src="item.FileUrl" alt=""/>
                            <div class="img-del">
                                <a-popconfirm
                                        placement="bottom"
                                        cancelText="取消"
                                        okText="确定"
                                        @confirm="ImgDel(index, item.Id)"
                                        @cancel="maxClassify = -1"
                                >
                                    <template slot="title">
                                        <p>确定删除么？</p>
                                    </template>
                                    <i
                                            style="color:#ffffff;cursor: pointer"
                                            class="iconfont icon-shanchu- ant-menu-item-selected"
                                    ></i>
                                </a-popconfirm>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">所用课件</h1>
                        <!--<a-upload>-->
                        <!--<a-button  v-if="Id" type="primary" >上传课件 </a-button>-->
                        <!--</a-upload>-->
                    </div>
                    <div
                            class="flex"
                            style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap"
                    >
                        <div v-if="tutorList" v-for="(item, key) in tutorList">
                            <div
                                    class="upload-file-item"
                                    v-for="(items, index) in item.CourseFile"
                            >
                                <div @click="priewCourse(items.FileUrl, items.FileType)">
                                    <a-icon type="file-pdf"/>
                                    <span
                                            style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block"
                                    >{{ items.FileName }}</span
                                    >
                                </div>
                                <div class="del-file">
                                    <a-popconfirm
                                            placement="bottom"
                                            cancelText="取消"
                                            okText="确定"
                                            @confirm.stop="CourseDel(key, index, items.Id)"
                                            @cancel="maxClassify = -1"
                                    >
                                        <template slot="title">
                                            <p>确定删除么？</p>
                                        </template>
                                        <i class="iconfont icon-shanchu- ant-menu-item-selected"
                                        >&nbsp</i
                                        >
                                    </a-popconfirm>
                                </div>
                                <!--<a-dropdown :trigger="['contextmenu']">-->
                                <!--<div>-->
                                <!--<a-icon type="file-pdf" />-->
                                <!--<span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block">{{items.FileName}}</span>-->
                                <!--</div>-->
                                <!--<a-menu slot="overlay">-->
                                <!--<a-menu-item key="1">-->
                                <!--<a-popconfirm placement="bottom" cancelText="取消" okText="确定" @confirm="CourseDel(key,index,items.Id)" @cancel="maxClassify=-1">-->
                                <!--<template slot="title">-->
                                <!--<p>确定删除么？</p>-->
                                <!--</template>-->
                                <!--<i  class="iconfont icon-shanchu- ant-menu-item-selected">&nbsp;删除</i>-->
                                <!--</a-popconfirm>-->
                                <!--</a-menu-item>-->
                                <!--<a-menu-item key="2" @click="downCourse(items.FileUrl,items.FileName)">-->
                                <!--<i class="iconfont icon-xiazai-">&nbsp;下载</i>-->
                                <!--</a-menu-item>-->
                                <!--<a-menu-item key="3" @click="priewCourse(items.FileUrl,items.FileType)">-->
                                <!--<i class="iconfont icon-yulan">&nbsp;预览</i>-->
                                <!--</a-menu-item>-->
                                <!--</a-menu>-->
                                <!--</a-dropdown>-->

                                <!--<a-icon type="file-pdf" />-->
                                <!--<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{items.FileName}}</div>-->
                                <!--<a-popconfirm placement="bottom" cancelText="取消" okText="确定" @confirm="CourseDel(key,index,items.Id)" @cancel="maxClassify=-1">-->
                                <!--<template slot="title">-->
                                <!--<p>确定删除么？</p>-->
                                <!--</template>-->
                                <!--<i  class="iconfont icon-shanchu- ant-menu-item-selected"></i>-->
                                <!--</a-popconfirm>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">试卷</h1>
                        <a-button @click="QuireData(2)" type="primary">选择试卷</a-button>
                    </div>
                    <div
                            v-if="PaperInfo"
                            v-for="(item, index) in PaperInfo"
                            class="quire-content"
                    >
                        <span class="quire-icon"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="QuireDel(item.Id, 'PaperId', index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shibai"></i>
                        </a-popconfirm>
                    </div>
                </div>
                <div class="warp-padding">
                    <div class="flex drawer-content-bottom">
                        <h1 class="base-text">问卷</h1>
                        <a-button @click="QuireData(1)" type="primary">选择问卷</a-button>
                    </div>
                    <div
                            v-if="QuesData"
                            v-for="(item, index) in QuesData"
                            class="quire-content"
                    >
                        <span class="Paper-bg"></span>
                        <span class="quire-title">{{ item.Title }}</span>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="QuireDel(item.Id, 'QuestionnaireId', index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shibai"></i>
                        </a-popconfirm>
                    </div>
                </div>
            </a-col>
        </a-row>
        <!--添加视频  -->
        <a-drawer
                width="400px"
                placement="right"
                :closable="false"
                @close="onClose('visibleVideo')"
                :visible="visibleVideo"
        >
            <div class="drawer-head">添加视频</div>
            <div class="drawer">
                <div class=" drawer-content-bottom">
                    <a-textarea
                            placeholder="请粘贴,多个连接请以英文逗号(',')隔开"
                            v-model="VideoAdd"
                            :rows="3"
                    ></a-textarea>
                    请将视频在文件柜，在视频详情信息里复制链接过来，
                </div>
            </div>
            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('visibleVideo')"
                    >取消
                    </a-button
                    >
                    <a-button @click="videoAddFn()" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--导师抽屉start-->
        <a-drawer
                width="400px"
                placement="right"
                :closable="false"
                @close="onClose('visible')"
                :visible="visible"
        >
            <div class="drawer-head">导师信息</div>
            <div class="drawer">
                <div class="flex drawer-content-bottom">
                    <div style="position: relative">
                        <a-avatar
                                class="person-avatar"
                                :size="52"
                                icon="user"
                                shape="circle"
                                :src="tutorImg + '?imageView2/1/w/92/h/92'"
                        />
                        <input
                                accept=".jpg,.png,.gif,.jpeg"
                                v-if="tutorType == 2"
                                type="file"
                                class="upload-bth"
                                id="tutorImg"
                                @change="_upload($event, 'tutorImg')"
                        />
                    </div>

                    <person
                            v-bind="persontutor"
                            v-if="tutorType == 1"
                            style="flex:1;margin-left: 15px;"
                            @getData="addtutor"
                    ></person>
                    <a-input
                            v-else
                            placeholder="请输入主讲人姓名"
                            v-model="SpeakerName"
                            style="flex: 1;margin-left: 15px"
                    />
                </div>
                <div class="drawer-content-bottom">
                    <a-textarea
                            v-model="Info"
                            placeholder="主讲人简介"
                            :autosize="{ minRows: 6 }"
                    />
                </div>
                <div class="flex drawer-content-bottom">
                    <span class="label-tips">主讲时间</span>
                    <a-date-picker
                            v-if="SpeakerTime"
                            :value="moment(SpeakerTime, 'YYYY-MM-DD HH:mm')"
                            style="flex:1;margin-left: 15px;"
                            show-time
                            format="YYYY-MM-DD HH:mm"
                            @change="ChangeSpeakerTime"
                    />
                    <a-date-picker
                            v-else
                            style="flex:1;margin-left: 15px;"
                            show-time
                            format="YYYY-MM-DD HH:mm"
                            @change="ChangeSpeakerTime"
                    />
                </div>
                <div class="flex drawer-content-bottom">
                    <span class="label-tips">所用课件</span>
                    <a-button type="primary" style="position: relative">
                        上传课件
                        <input
                                type="file"
                                class="upload-bth"
                                id="CourseFile"
                                @change="_upload($event, 'CourseFile')"
                        />
                    </a-button>
                </div>
                <div
                        class="flex"
                        style="justify-content: left;margin-bottom: 15px;flex-wrap: wrap"
                        v-if="tutorCourseFile"
                >
                    <div
                            class="upload-file-item"
                            v-for="(item, index) in tutorCourseFile"
                    >
                        <a-icon type="file-pdf"/>
                        <div
                                style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden"
                        >
                            {{ item.FileName }}
                        </div>
                        <a-popconfirm
                                placement="bottom"
                                cancelText="取消"
                                okText="确定"
                                @confirm="CourseDels(index)"
                                @cancel="maxClassify = -1"
                        >
                            <template slot="title">
                                <p>确定删除么？</p>
                            </template>
                            <i class="iconfont icon-shanchu- ant-menu-item-selected"></i>
                        </a-popconfirm>
                    </div>
                </div>
                <div class="flex drawer-content-bottom" v-if="tutorType == 1">
                    <span class="label-tips">提醒导师</span>
                    <a-button
                            type="primary"
                            @click="send_speaker(tutorList[SaveTutorStatus]['id'])"
                            style="position: relative"
                    >提醒导师
                    </a-button
                    >
                </div>
            </div>
            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('visible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="TutorInfo" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>

        <!--导师抽屉end-->
        <!--试卷抽屉start-->
        <a-drawer
                width="800px"
                placement="right"
                :closable="false"
                @close="onClose('quirevisible')"
                :visible="quirevisible"
        >
            <div class="drawer-head">
                {{ QuireType == 1 ? '问卷信息' : '试卷信息' }}
            </div>
            <div class="search-flex">
                <div class="search-list-item">
                    <span class="search-item-label ">学习主题</span>
                    <a-input
                            v-model="Keyword"
                            placeholder="请输入关键字"
                            class="search-item-input"
                    />
                </div>
            </div>
            <div class="search-flex" style="width:200px">
                <div class="search-list-item">
                    <a-button
                            size="large"
                            class="search-item-button"
                            @click="QuireData(QuireType)"
                            type="primary"
                    >查询
                    </a-button
                    >
                    <a-button class="search-item-button">重置</a-button>
                </div>
            </div>
            <a-table
                    v-if="quirevisible"
                    bordered
                    :rowSelection="rowSelection"
                    :columns="columns"
                    :dataSource="quireList"
            >
                <a slot="sNumber" slot-scope="sNumber" href="javascript:;">{{
                    sNumber
                    }}</a>
            </a-table>
            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('quirevisible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="quireAdd(QuireType)" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--试卷抽屉end-->
        <!--视频抽屉start-->
        <a-drawer
                width="500px"
                placement="right"
                :closable="false"
                @close="onClose('videovisible')"
                :visible="videovisible"
        >
            <div class="drawer-head">视频信息</div>
            <div class="flex drawer-content-bottom">
                <span class="label-tips">视频名称</span>
                <video
                        style="width:300px;height:300px"
                        controls="controls"
                        controlslist="nodownload"
                        :src="VideoData.FileUrl"
                ></video>
            </div>
            <div class="flex drawer-content-bottom">
                <span class="label-tips">视频名称</span>
                <a-input v-model="VideoName" style="flex: 1;margin-left: 15px"/>
            </div>

            <div class="drawer-bottom">
                <div style="width: 150px" class="flex">
                    <a-button style="margin-right: 20px" @click="onClose('videovisible')"
                    >取消
                    </a-button
                    >
                    <a-button @click="VideoEdit(VideoNum)" type="primary">确认</a-button>
                </div>
            </div>
        </a-drawer>
        <!--视频抽屉end-->
        <!--课件预览start-->

        <div class="popup-warp" v-if="FileType">
            <div class="popup-shadw"></div>
            <div class="popup-content">
                <iframe
                        v-if="FileStatus == 2"
                        :src="'https://view.officeapps.live.com/op/view.aspx?src=' + FileUrl"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style="position: fixed;left: 50%;top: 50%;transform: translate(-50%,-50%);width: 80%;height: 86%"
                ></iframe>
                <div
                        style="position: fixed;left: 50%;top: 50%;transform: translate(-50%,-50%);"
                        v-if="FileStatus == 1"
                >
                    <img :src="FileUrl" alt=""/>
                </div>
                <div
                        v-if="FileStatus == 3"
                        style="position: fixed;left: 50%;top: 50%;transform: translate(-50%,-50%);width: 100%;height: 100%"
                >
                    <object style="width: 100%;height: 100%" border="0" :data="FileUrl">
                    </object>
                </div>
            </div>
            <div @click="FileClose" class="popup-close">
                <i style="font-size: 18px" class="iconfont icon-guanbi"></i>
            </div>
        </div>
        <!--课件预览end-->

        <div
                v-show="spinning"
                style="position:fixed;left:0%;bottom:0px;z-index:1000;width:100%;height:100%;background:rgba(0,0,0,.4);"
        >
            <a-spin style="margin: 50px 48%;">
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
            <div style="text-align:center;font-size:20px;color:#e1e1e1;">
                正在上传excel人员表,请等待...
            </div>
        </div>

        <!--视频数据弹出框-->
        <a-drawer
                title="视频数据"
                placement="right"
                width="1200"
                :closable="false"
                @close="_closeVideo"
                :visible="videoAlert"
        >
            <a-input
                    placeholder="搜索姓名"
                    style="width: 150px;margin-left: 15px"
                    v-model="searchInput"
            />


            <a-select allowClear :value="isIn" placeholder="是否参与" style="width: 200px;margin-left:10px" @change="_onChangeIn">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
            </a-select>

            <a-select allowClear :value="isLookLive" placeholder="是否观看直播" style="width: 200px;margin-left:10px" @change="_onChangeLookLive">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
            </a-select>

            <a-select allowClear :value="isLookBack" placeholder="是否观看回放" style="width: 200px;margin-left:10px" @change="_onChangeLookBack">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
            </a-select>

            <a-button @click="emptyData" class="search-item-button" style="margin-left:10px">重置</a-button>

            <a-button type="primary" style="margin-left: 15px;" @click="_search">查询</a-button>

            <div style="text-align: right;margin-bottom: 10px;">
                <a-button type="primary" @click="_export">导出</a-button>
            </div>
            <a-table
                    :columns="alertVideoColumns"
                    :dataSource="alertVideoData"
                    :pagination="false"
            >
        <span
                slot="videoUrl"
                slot-scope="text"
                @click="_skipAudio(text)"
                style="color: #1890FF;cursor: pointer"
        >打开>></span
        >
            </a-table>
            <div
                    style="text-align: right;margin-top: 15px;"
                    v-show="alertVideoDataCount > 10"
            >
                <a-pagination
                        :total="alertVideoDataCount"
                        showLessItems
                        @change="_pagination"
                />
            </div>
        </a-drawer>

        <div class="alert-video" v-show="alertVideo">
            <video
                    :src="alertVideoUrl"
                    class="play-video"
                    controls
                    @play="_strtVideo"
                    @pause="_endVideo"
                    @ended="_endedVideo"
                    controlslist="nodownload"
            ></video>
        </div>
        <div
                class="alert-video-shade"
                v-show="alertVideo"
                @click="_closeAlertVideo"
        ></div>
    </div>
</template>
<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'v-viewer'
    import Vue from 'vue'

    Vue.use(Viewer)

    import person from '../../components/SelectPersonnel/SelectPersonnel'
    import moment from 'moment'

    export default {
        name: 'meeting',
        components: {person},
        data() {
            return {
                alertVideoColumns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        key: 'sNumber',
                        align: 'center'
                    },
                    {
                        title: '主题',
                        dataIndex: 'PlanName',
                        key: 'PlanName'
                    },
                    // {
                    //     title: '视频名称',
                    //     dataIndex: 'VideoName',
                    //     key: 'VideoName'
                    // },
                    // {
                    //     title: '视频链接',
                    //     dataIndex: 'VideoUrl',
                    //     key: 'VideoUrl',
                    //     scopedSlots: {customRender: 'videoUrl'},
                    //     align: 'center'
                    // },
                    {
                        title: '姓名',
                        dataIndex: 'CreateUser',
                        key: 'CreateUser'
                    },
                    {
                        title: '员工号',
                        dataIndex: 'Num',
                        key: 'Num'
                    },
                    {
                        title: '部门名称',
                        dataIndex: 'PartName',
                        key: 'PartName'
                    },
                    {
                        title: '观看直播时长',
                        dataIndex: 'LookLiveTime',
                        key: 'LookLiveTime'
                    },
                    {
                        title: '观看回放时长',
                        dataIndex: 'LookBackTime',
                        key: 'LookBackTime'
                    },
                    {
                        title: '观看总时长',
                        dataIndex: 'ViewDuration',
                        key: 'viewDuration',
                        align: 'center'
                    }
                ],
                alertVideoData: [

                ],
                alertVideoDataCount: 0,
                dateFormat: 'YYYY-MM-DD HH:mm:ss',
                Photo: '',
                Video: [],
                PersonInCharge: '',
                Title: '',
                JoinType: '1',
                JoinPersoin: [],
                JoinPersoinCount: '0',
                visible: false,
                quirevisible: false,
                videovisible: false,
                tutorList: [],
                tutorType: '1',
                Remark: '',
                MeetingAddress: '',
                SignUp: '',
                tutorImg: '',
                StartTime: '',
                EndTime: '',
                Form: '1',
                files: '',
                CourseFile: '',
                Info: '',
                bool: false,
                CourseFiles: '',
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                personjoin: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1000, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                persontutor: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: '', //常用联系人 不要常用联系人传空''
                    selectArr: [] //要传入的数据
                },
                PersonInChargeImg: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                Id: this.$route.query.id ? this.$route.query.id : '',
                Keyword: '',
                SpeakerTime: '2019-04-03 12:12',
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'sNumber',
                        scopedSlots: {customRender: 'sNumber'}
                    },
                    {title: '试卷名称', dataIndex: 'Title'},
                    {title: '所属分类', dataIndex: 'TypeName'}
                ],
                Changequirelist: '',
                ChangePaperlist: '',
                QuireType: '',
                PaperInfo: '',
                QuesData: '',
                tutorPerson: '',
                active: -1,
                active2: -1,
                nowTime: '',
                VideoFile: [],
                SpeakerName: '',
                PersonDelStatus: -1,
                SaveTutorStatus: '',
                tutorbase: [],
                VideoData: '',
                VideoNum: '',
                VideoName: '',
                tutorCourseFile: '',
                FileType: false,
                FileUrl: '',
                FileStatus: '',
                quireList: [],
                createUser: '',
                VideoAdd: '',
                visibleVideo: false,
                loadShow: true,
                spinning: false,
                copyUrl: '',
                videoAlert: false,
                alertVideo: false,
                alertVideoUrl: '',
                alertVideoName: '',
                duration: 0,
                timer: '',
                searchInput: '',
                isIn:undefined,
                isLookLive:undefined,
                isLookBack:undefined,
                CostFree:0,
                categoryList:[],
                Category:'',
            }
        },
        created() {
            $('.ant-select-clear').trigger('click')
            $('.ant-input-clear-icon').trigger('click')
            if (this.Id) {
                this.copyUrl = 'https://mskim.sikegroup.com/studyPlanDetail?id=' + this.Id
                this.loadData()
            }
            this._categoryList();
        },
        computed: {
            rowSelection() {
                const {selectedRowKeys} = this
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        let selectedId = ''
                        if (selectedRows.length > 0) {
                            for (var i = 0; i < selectedRows.length; i++) {
                                selectedId += selectedRows[i]['Id'] + ','
                            }
                        }
                        if (this.QuireType == 1) {
                            if (!this.Id) {
                                console.log(selectedRows)
                                if (this.QuesData) {
                                    this.QuesData.push(selectedRows[0])
                                } else {
                                    this.QuesData = selectedRows
                                }
                            }
                            this.Changequirelist = selectedId
                        } else {
                            if (!this.Id) {
                                if (this.PaperInfo) {
                                    this.PaperInfo.push(selectedRows[0])
                                } else {
                                    this.PaperInfo = selectedRows
                                }
                            }
                            this.ChangePaperlist = selectedId
                        }
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            }
        },
        methods: {
            moment,
            _categoryList() {
                let self = this
                this.$axios.get(
                    1200,
                    {
                        label: 'Study'
                    },
                    res => {
                        self.categoryList = res.data.data
                    }
                )
            },
            _export() {
                this.$message.loading('文件下载中...', 0)
                //数据导出
                this.$axios.get(
                    5836146,
                    {PlanId: this.Id, PlanName: this.Title, UserId: this.userinfo.uid},
                    res => {
                        if (res.data.code == 1) {
                            window.open(res.data.url)
                            this.$message.destroy()
                        } else {
                            this.$message.error(res.data.msg)
                            this.$message.destroy()
                        }
                    }
                )
            },
            _pagination(page) {
                // this.$axios.get(5836145, {Page: page, Limit: 10, Id: this.Id}, res => {
                //     if (res.data.code == 1) {
                //         this.alertVideoData = res.data.data
                //         this.alertVideoDataCount = res.data.count
                //     }
                // })

                this._showVideoAlert(page);
            },
            _skipAudio(audio) {
                window.open(audio)
            },
            _showVideoAlert(page = 1) {
                this.$axios.get(
                    5836145,
                    {
                        Page: page,
                        Limit: 10,
                        Id: this.Id,
                        Keyword: this.searchInput ? this.searchInput : '',
                        isIn:this.isIn,
                        isLookLive:this.isLookLive,
                        isLookBack:this.isLookBack,
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.alertVideoData = res.data.data
                            this.alertVideoDataCount = res.data.count
                        } else {
                            this.alertVideoData = [];
                            this.alertVideoDataCount = 0;
                        }
                    }
                )
                this.videoAlert = true
            },
            _strtVideo() {
                let self = this
                this.timer = setInterval(function () {
                    self.duration += 1
                }, 1000)
            },
            _endVideo() {
                clearInterval(this.timer)
                console.log(this.duration)
            },
            _endedVideo() {
                let data = {
                    PlanId: this.Id, //学习计划id
                    PlanName: this.Title, //学习计划名称
                    VideoUrl: this.alertVideoUrl, //视频地址
                    CreateUserId: this.userinfo.uid, //观看人id
                    CreateUser: this.userinfo.name, //观看人名称
                    ViewDuration: this.duration, //观看时长（单位/秒）
                    VideoName: this.alertVideoName //视频名称
                }
                this.$axios.post(5836144, data, function (res) {
                    if (res.data.code == 1) {
                        return 'OK'
                    }
                })
                this.timer = ''
                this.duration = 0
            },
            _search() {
                this._showVideoAlert(1)
            },
            _closeAlertVideo() {
                this.alertVideo = false
                document.getElementsByClassName('play-video')[0].pause()
                if (this.duration == 0) {
                    return false
                }
                let data = {
                    PlanId: this.Id, //学习计划id
                    PlanName: this.Title, //学习计划名称
                    VideoUrl: this.alertVideoUrl, //视频地址
                    CreateUserId: this.userinfo.uid, //观看人id
                    CreateUser: this.userinfo.name, //观看人名称
                    ViewDuration: this.duration, //观看时长（单位/秒）
                    VideoName: this.alertVideoName //视频名称
                }
                this.$axios.post(5836144, data, function (res) {
                    if (res.data.code == 1) {
                        return 'OK'
                    }
                })
            },
            _selectVideo(data) {
                this.alertVideo = true
                if (this.alertVideoUrl != data.FileUrl) {
                    this.alertVideoUrl = data.FileUrl
                    this.alertVideoName = data.FileName
                    this.timer = ''
                    this.duration = 0
                }
                console.log(data)
            },
            _closeVideo() {
                this.videoAlert = false;
                this.isIn = undefined;
                this.isLookLive = undefined;
                this.isLookBack = undefined;
                console.log(1111)
            },
            loadMoveFn() {
                let that = this
                that.$axios.get(8021, {id: that.Id}, res => {
                    if (res.data.code == 1) {
                        this.loadShow = false
                        that.JoinPersoin = []
                        that.JoinPersoin = res.data.data
                        console.log(that.JoinPersoin)
                        // if(that.JoinPersoin.length>20){this.loadShow = false;}
                    } else {
                        that.$message.error(res.data.msg)
                    }
                })
            },
            _copyUrl() {
                this.$refs.copy.select()
                document.execCommand('Copy')
                this.$message.success('复制成功')
            },
            excelFn(e) {
                let self = this
                self.spinning = true
                let file = []
                let files = document.getElementById('excel').files
                this.__uploadFile__(files).then(res => {
                    console.log(res)
                    if (res.length > 0) {
                        self.$message.success('上传成功')
                        self.$axios.post(
                            8020,
                            {
                                id: self.Id,
                                uid: self.userinfo.uid,
                                url: res[0].FileUrl
                            },
                            ress => {
                                self.spinning = false
                                if (ress.data.code == 1) {
                                    self.$message.success('录入成功')
                                } else {
                                    self.$message.success(ress.data.msg)
                                }
                            }
                        )
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },

            _upload(e, name) {
                let self = this
                let file = []
                let files = document.getElementById(name).files
                this.__uploadFile__(files).then(res => {
                    console.log(res)
                    if (res.length > 0) {
                        let url = res[0]['FileUrl']
                        if (name == 'Photo') {
                            self.Photo = url
                        }
                        if (name == 'Video') {
                            self.getDate()
                            self.VideoFile = res[0]
                            self.VideoFile.nowTime = self.nowTime
                            self.Video.push(self.VideoFile)
                        }
                        if (name == 'tutorImg') {
                            self.tutorImg = url
                        }
                        if (name == 'CourseFile') {
                            if (self.tutorCourseFile.length > 0) {
                                self.tutorCourseFile.push(res[0])
                            } else {
                                self.tutorCourseFile = res
                            }
                        }
                        self.$message.success('上传成功')
                    } else {
                        this.$message.error('上传文件失败!')
                    }
                })
            },
            videoAddFn() {
                console.log(this.Video, this.VideoAdd)
                this.visibleVideo = false
                let that = this
                if (that.VideoAdd) {
                    that.$axios.get(8019, {Urls: that.VideoAdd}, res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            let arr = res.data.data
                            for (let i = 0; i < arr.length; i++) {
                                that.Video.push(arr[i])
                            }
                        } else {
                            that.$message.error(res.data.message)
                        }
                    })
                }
            },
            ActivityImg(e, name, type) {
                console.log(type)
                let files = document.getElementById(name).files
                let self = this
                this.__uploadFile__(files).then(res => {
                    if (res.length > 0) {
                        this.$axios.post(
                            1028,
                            {
                                File: JSON.stringify(res),
                                CreateUserId: self.userinfo.uid,
                                CreateUserName: self.userinfo.name,
                                PersonId: self.Id,
                                Type: type
                            },
                            data => {
                                if (data.data.code == 1) {
                                    self.loadData()
                                }
                                //console.log(data);
                            }
                        )
                    } else {
                        this.$message.error('上传图片失败!')
                    }
                })
            },
            //导师消息提醒
            send_speaker(id) {
                console.log(id)
                let self = this
                var xhr = new XMLHttpRequest()

                var formData = new FormData()
                formData.append('plan_id', self.Id)
                formData.append('UserId', id)
                formData.append('uid', self.userinfo.uid)
                formData.append('username', self.userinfo.name)
                xhr.open(
                    'POST',
                    'https://ims.qitianzhen.cn/index.php?m=Kms&c=Batch&a=send_speaker'
                )
                xhr.send(formData)
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        var result = JSON.parse(xhr.responseText)
                        console.log(result)
                        if (result.code == 1) {
                            self.$message.success(result.message)
                        } else {
                            self.$message.error(result.message)
                        }
                    } else {
                    }
                }
            },
            TutorInfo() {
                if (this.Info == '') {
                    this.$message.error('请输入导师简介')
                    return false
                }
                if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                    if (this.tutorList[this.SaveTutorStatus]['PersonId']) {
                        let self = this
                        let person = {}
                        if (this.tutorType == 2) {
                            person.icon = this.tutorImg
                            person.title = this.SpeakerName
                            if (this.tutorImg == '') {
                                this.$message.error('请先上传导师头像')
                                return false
                            }
                            if (this.SpeakerName == '') {
                                this.$message.error('请输入导师名字')
                                return false
                            }
                        } else {
                            person = this.tutorPerson
                        }
                        this.$axios.post(
                            1034,
                            {
                                Info: self.Info,
                                CourseFile: JSON.stringify(self.tutorCourseFile),
                                SpeakerTime: self.SpeakerTime,
                                person: JSON.stringify(person),
                                plad_id: self.Id,
                                Id: self.tutorList[self.SaveTutorStatus]['PersonId'],
                                uid: self.userinfo.uid,
                                SpeakerTime: this.SpeakerTime
                            },
                            res => {
                                if (res.data.code == 1) {
                                    self.persontutor.selectArr = ''
                                    self.SpeakerName = ''
                                    self.tutorPerson = ''
                                    self.SaveTutorStatus = ''
                                    self.Info = ''
                                    self.tutorImg = ''
                                    self.SpeakerTime = ''
                                    self.tutorCourseFile = ''
                                    self.visible = false;
                                    self.Title= '';
                                    self.loadData();
                                    self.$message.success(res.data.message)
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            }
                        )
                    } else {
                        if (this.tutorType == 2) {
                            if (this.tutorImg == '') {
                                this.$message.error('请先上传导师头像')
                                return false
                            }
                            if (this.SpeakerName == '') {
                                this.$message.error('请输入导师名字')
                                return false
                            }
                            let tutorinfos = {}
                            if (this.tutorCourseFile) {
                                tutorinfos.CourseFile = this.tutorCourseFile
                            }
                            tutorinfos.icon = this.tutorImg
                            tutorinfos.title = this.SpeakerName
                            tutorinfos.Info = this.Info
                            tutorinfos.SpeakerTime = this.SpeakerTime
                            if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                                this.tutorList.splice(this.SaveTutorStatus, 1)
                            }
                            this.tutorList.push(tutorinfos)
                        } else {
                            this.tutorPerson.Info = this.Info
                            this.tutorPerson.SpeakerTime = this.SpeakerTime
                            this.tutorPerson.CourseFile = this.tutorCourseFile
                            this.tutorList.push(this.tutorPerson)
                        }
                        this.persontutor.selectArr = ''
                        this.SpeakerName = ''
                        this.tutorPerson = ''
                        this.SaveTutorStatus = ''
                        this.Info = ''
                        this.tutorImg = ''
                        this.SpeakerTime = ''
                        this.tutorCourseFile = ''
                        this.visible = false
                    }
                } else {
                    if (this.tutorType == 2) {
                        if (this.tutorImg == '') {
                            this.$message.error('请先上传导师头像')
                            return false
                        }
                        if (this.SpeakerName == '') {
                            this.$message.error('请输入导师名字')
                            return false
                        }
                        let tutorinfos = {}
                        if (this.tutorCourseFile) {
                            tutorinfos.CourseFile = this.tutorCourseFile
                        }
                        tutorinfos.icon = this.tutorImg
                        tutorinfos.title = this.SpeakerName
                        tutorinfos.Info = this.Info
                        tutorinfos.SpeakerTime = this.SpeakerTime
                        if (this.SaveTutorStatus == '0' || this.SaveTutorStatus) {
                            this.tutorList.splice(this.SaveTutorStatus, 1)
                        }
                        let self = this
                        this.$axios.post(
                            1045,
                            {
                                tutorPerson: JSON.stringify(tutorinfos),
                                CreateUserId: self.userinfo.uid,
                                CreateUser: self.userinfo.name,
                                plan_id: self.Id
                            },
                            res => {
                                if (res.data.code == 1) {
                                    self.loadData()
                                    this.$message.success(res.data.message)
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            }
                        )
                    } else {
                        this.tutorPerson.Info = this.Info
                        this.tutorPerson.SpeakerTime = this.SpeakerTime
                        this.tutorPerson.CourseFile = this.tutorCourseFile
                        let self = this
                        this.$axios.post(
                            1045,
                            {
                                tutorPerson: JSON.stringify(self.tutorPerson),
                                CreateUserId: self.userinfo.uid,
                                CreateUser: self.userinfo.name,
                                plan_id: self.Id
                            },
                            res => {
                                console.log(res)
                                if (res.data.code == 1) {
                                    self.loadData()
                                    this.$message.success(res.data.message)
                                } else {
                                    this.$message.error(res.data.message)
                                }
                            }
                        )
                    }
                    console.log(this.tutorList)
                    this.persontutor.selectArr = ''
                    this.SpeakerName = ''
                    this.tutorPerson = ''
                    this.SaveTutorStatus = ''
                    this.Info = ''
                    this.tutorImg = ''
                    this.SpeakerTime = ''
                    this.tutorCourseFile = ''
                    this.visible = false
                }
            },
            CourseDels(i) {
                if (this.SaveTutorStatus) {
                    let self = this
                    this.$axios.post(
                        1037,
                        {
                            uid: self.userinfo.uid,
                            Id: self.tutorCourseFile[i]['Id'],
                            plan_id: self.Id
                        },
                        res => {
                            if (res.data.code == 1) {
                                self.tutorCourseFile.splice(i)
                                self.$message.success(res.data.message)
                            } else {
                                self.$message.error(res.data.message)
                            }
                        }
                    )
                } else {
                    this.tutorCourseFile.splice(i)
                }
            },
            FileClose() {
                this.FileType = false
            },
            downCourse(url, name) {
                window.open(url + '?attname=' + name)
            },
            priewCourse(url, type) {
                this.FileStatus = this.CheckFileType(type)
                if (this.FileStatus != 0) {
                    this.FileType = true
                    this.FileUrl = url
                } else {
                    this.$message.error('该课件不支持预览')
                }
            },
            CheckFileType(type) {
                let status = 0
                if (type.indexOf('image/') == 0) {
                    status = 1
                } else if (type.indexOf('application/') == 0) {
                    if (type.indexOf('application/pdf') == 0) {
                        status = 3
                    } else {
                        status = 2
                    }
                }
                return status
            },
            SaveTutor(i,e) {
                this.active2 = -1;
                this.active = -1;
                this.Info = this.tutorList[i].Info
                this.tutorImg = this.tutorList[i].icon
                this.tutorCourseFile = this.tutorList[i].CourseFile
                this.SpeakerTime = this.tutorList[i].SpeakerTime
                if (this.tutorList[i].id) {
                    //内部导师
                    this.tutorType = 1
                    let tutorbases = {}
                    tutorbases.icon = this.tutorList[i].icon
                    tutorbases.id = this.tutorList[i].id
                    tutorbases.isuser = this.tutorList[i].isuser
                    tutorbases.position = this.tutorList[i].position
                    tutorbases.title = this.tutorList[i].title
                    this.tutorbase.push(tutorbases)
                    this.persontutor.selectArr = this.tutorbase
                    this.tutorPerson = this.tutorbase[0]
                    this.tutorbase = []
                } else {
                    this.tutorType = 2
                    this.SpeakerName = this.tutorList[i].title
                }

                this.tutorImg = this.tutorList[i].icon
                this.SaveTutorStatus = i
                this.visible = true
            },
            TutorDel(id, i, type) {
                this.active2 = -1
                this.active = -1
                let self = this
                this.$axios.get(
                    1031,
                    {
                        PersonId: id,
                        UserId: self.userinfo.uid,
                        plan_id: self.Id
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            if (type == 2) {
                                self.JoinPersoin.splice(i, 1)
                            } else {
                                self.tutorList.splice(i, 1)
                            }
                            //this.loadData();
                            this.$message.success('删除成功')
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            SaveVideo(i) {
                this.VideoNum = i
                this.VideoData = this.Video[i]
                this.VideoName = this.Video[i]['FileName']
                this.videovisible = true
            },
            VideoEdit(i) {
                let self = this
                self.Video.splice(i, 1)
                self.VideoData.FileName = self.VideoName
                self.Video.push(self.VideoData)
                if (self.Id) {
                    this.$axios.post(
                        1036,
                        {
                            uid: self.userinfo.uid,
                            Id: self.Id,
                            Video: JSON.stringify(self.Video)
                        },
                        res => {
                            console.log(res)
                            if (res.data.code == 1) {
                                self.videovisible = false
                                self.$message.success(res.data.message)
                            } else {
                                self.$message.error(res.data.message)
                            }
                        }
                    )
                } else {
                    self.videovisible = false
                    //self.Video = self.Video.push(this.VideoData);
                }
            },
            VideoDel(i) {
                let self = this
                if (self.Id) {
                    let video = ''
                    if (self.Video.length == 1) {
                        video = []
                    } else {
						self.Video.splice(i, 1)
                        video = JSON.stringify(self.Video)
                    }
                    this.$axios.post(
                        1036,
                        {
                            uid: self.userinfo.uid,
                            Id: self.Id,
                            Video: video
                        },
                        res => {
                            if (res.data.code == 1) {
                                this.$message.success(res.data.message)
                            } else {
                                this.$message.error(res.data.message)
                            }
                        }
                    )
                } else {
                    if (self.Video.length == 1) {
                        self.Video = []
                    } else {
                        self.Video = self.Video.splice(i, 1)
                    }
                }
            },
            ImgDel(i, id) {
                let self = this
                this.$axios.post(
                    1037,
                    {
                        uid: self.userinfo.uid,
                        Id: id,
                        plan_id: self.Id
                    },
                    res => {
                        console.log(res.data.code)
                        if (res.data.code == 1) {
                            self.files.splice(i, 1)
                            self.$message.success(res.data.message)
                        } else {
                            self.$message.error(res.data.message)
                        }
                    }
                )
            },
            CourseDel(i, num, id) {
                let self = this
                this.$axios.post(
                    1037,
                    {
                        uid: self.userinfo.uid,
                        Id: id,
                        plan_id: self.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.tutorList[i]['CourseFile'].splice(num)
                            self.$message.success(res.data.message)
                        } else {
                            self.$message.error(res.data.message)
                        }
                    }
                )
            },
            signup() {
                let self = this
                this.$axios.post(
                    1035,
                    {
                        Id: self.Id,
                        userinfo: JSON.stringify(self.userinfo)
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.loadData()
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            //主讲时间
            ChangeSpeakerTime(date, dateString) {
                this.SpeakerTime = dateString
            },
            getCurrentData() {
                if (this.SpeakerTime) {
                    return this.SpeakerTime
                } else {
                    return new Date().toLocaleDateString()
                }
            },
            getDate() {
                let date = new Date()
                let seperator1 = '-'
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let strDate = date.getDate()
                if (month >= 1 && month <= 9) {
                    month = '0' + month
                }
                if (strDate >= 0 && strDate <= 9) {
                    strDate = '0' + strDate
                }
                let currentdate = year + seperator1 + month + seperator1 + strDate
                this.nowTime = currentdate
            },
            loadData() {
                let self = this
                this.$axios.get(
                    1026,
                    {
                        Id: self.Id
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.Title = res.data.message.Title
                            self.SignUp = res.data.message.SignUp
                            self.Photo = res.data.message.Photo
                            if (res.data.message.Video) {
                                self.Video = JSON.parse(res.data.message.Video)
                            }
                            self.createUser = res.data.message.CreateUser
                            self.tutorList = res.data.message.tutorList
                            self.JoinPersoin = res.data.message.JoinPersoin
                            self.Remark = res.data.message.Remark
                            self.MeetingAddress = res.data.message.MeetingAddress
                            self.StartTime = res.data.message.StartTime
                            self.Form = res.data.message.Form
                            self.EndTime = res.data.message.EndTime
                            self.JoinType = res.data.message.JoinType;
                            self.CostFree = res.data.message.CostFree;
                            self.Category = res.data.message.Category;
                            self.personjoin.selectArr = res.data.message.JoinPersoin
                            self.PersonInCharge = res.data.message.PersonInCharge
                            if (res.data.message.PersonInCharge.length > 0) {
                                self.personObj.selectArr = res.data.message.PersonInCharge
                                self.PersonInChargeImg = res.data.message.PersonInCharge[0].icon
                            }
                            self.JoinPersoinCount = res.data.message.JoinPersoin.length
                            self.CourseFiles = res.data.message.CourseFile
                            if (res.data.message.files) {
                                self.files = res.data.message.files.photo
                            }
                            self.PaperInfo = res.data.message.PaperId
                            self.QuesData = res.data.message.QuestionnaireId
                            self.bool = true
                        } else {
                            this.$message.error('查询数据失败!')
                        }
                    }
                )
            },
            submitInfo() {
                let Title = this.Title
                let Remark = this.Remark
                let SignUp = this.SignUp
                let PersonInCharge = JSON.stringify(this.PersonInCharge)
                let Photo = this.Photo
                let Video = JSON.stringify(this.Video)
                let MeetingAddress = this.MeetingAddress
                let StartTime = this.StartTime
                let EndTime = this.EndTime
                let JoinType = this.JoinType
                let Form = this.Form
                let self = this
                if (Title == '') {
                    this.$message.error('请输入标题')
                    return false
                }
                if (StartTime == '' || EndTime == '') {
                    this.$message.error('请选择开始时间和结束时间')
                    return false
                }
                if (MeetingAddress == '') {
                    this.$message.error('请输入地址')
                    return false
                }
                if (Form == '') {
                    this.$message.error('请选择参与形式')
                    return false
                }
                if (JoinType == '') {
                    this.$message.error('请选择参与方式')
                    return false
                }
                if(!this.Category){
                    this.$message.error('请选择课程类型')
                    return false
                }
                let QuestionnaireId = ''
                let PaperId = ''
                if (this.QuesData) {
                    for (var i = 0; i < this.QuesData.length; i++) {
                        QuestionnaireId += this.QuesData[i]['Id'] + ','
                    }
                }
                if (this.PaperInfo) {
                    for (var i = 0; i < this.PaperInfo.length; i++) {
                        PaperId += this.PaperInfo[i]['Id'] + ','
                    }
                }
                this.$axios.post(
                    1022,
                    {
                        Title: Title,
                        Remark: Remark,
                        SignUp: SignUp,
                        PersonInCharge: PersonInCharge,
                        Photo: Photo,
                        Video: Video,
                        MeetingAddress: MeetingAddress,
                        CreateUserId: self.userinfo.uid,
                        CreateUser: self.userinfo.name,
                        Form: Form,
                        JoinType: JoinType,
                        StartTime: StartTime,
                        EndTime: EndTime,
                        Type: 1,
                        Id: self.Id,
                        QuestionnaireId: QuestionnaireId,
                        PaperId: PaperId,
                        CostFree:self.CostFree,
                        Category:self.Category,
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            this.$router.push({path: '/meeting'})
                            this.$message.success('操作成功')
                            this.loadData()
                        } else {
                            this.$message.error('操作失败')
                        }
                    }
                )
            },
            ChangeTime(time, timeString) {
                this.StartTime = timeString[0]
                this.EndTime = timeString[1]
            },
            QuireData(value) {
                let self = this
                this.QuireType = value
                let QuestionnaireId = ''
                if (value == 1) {
                    QuestionnaireId = JSON.stringify(this.QuesData)
                } else {
                    QuestionnaireId = JSON.stringify(this.PaperInfo)
                }
                console.log(QuestionnaireId)
                this.$axios.get(
                    1021,
                    {
                        Type: value,
                        Keyword: self.Keyword,
                        QuestionnaireId: QuestionnaireId
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.quirevisible = true
                            self.quireList = res.data.data
                        } else {
                            self.quireList = ''
                            this.$message.error('查询数据失败!')
                        }
                    }
                )
            },
            quireAdd() {
                let self = this
                this.$axios.get(
                    1029,
                    {
                        UserId: self.userinfo.uid,
                        Id: self.Id,
                        quirelist: self.Changequirelist,
                        PaperList: self.ChangePaperlist
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.loadData()
                            this.$message.success('添加成功')
                            self.Changequirelist = ''
                            self.ChangePaperlist = ''
                            self.quirevisible = false
                        } else {
                            this.$message.error('添加失败')
                        }
                    }
                )
            },
            QuireDel(QuireId, Field, i) {
                let self = this
                this.$axios.get(
                    1030,
                    {
                        QuireId: QuireId,
                        Field: Field,
                        UserId: self.userinfo.uid,
                        Id: self.Id
                    },
                    res => {
                        if (res.data.code == 1) {
                            this.$message.success('删除成功')
                            if (Field == 'PaperId') {
                                self.PaperInfo = res.data.message
                            } else {
                                self.QuesData = res.data.message
                            }
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            //导师抽屉
            showDrawer(e) {
                this.tutorType = e.key
                this.visible = true
            },
            onClose(value) {
                this.Keyword = ''
                this.quireList = '';

                if (value == 'quirevisible') {
                    this.quirevisible = false
                    this.ChangePaperlist = ''
                    this.Changequirelist = ''
                    this.QuireType = ''
                }
                if (value == 'videovisible') {
                    this.videovisible = false
                }
                if (value == 'visibleVideo') {
                    this.visibleVideo = false
                    this.VideoAdd = ''
                }
                if (value == 'visible') {
                    this.Title = '';
                    this.loadData();
                    this.persontutor.selectArr = ''
                    this.SpeakerName = ''
                    this.tutorPerson = ''
                    this.SaveTutorStatus = ''
                    this.Info = ''
                    this.tutorImg = ''
                    this.SpeakerTime = ''
                    this.tutorCourseFile = ''
                    this.visible = false ;
                    this.active2 = -1
                    this.active = -1
                }

            },
            //参与方式
            ChangeJoinType(value) {
                this.JoinType = value
            },
            //导师添加
            addtutor(value) {
                console.log(value)
                if (value.length > 0) {
                    this.tutorPerson = value[0]
                    if (value[0].icon) {
                        this.tutorImg = value[0].icon
                    }
                } else {
                    this.tutorPerson = ''
                    this.tutorImg = ''
                }
            },
            //负责人
            ChangeUser(value) {
                this.PersonInCharge = value
                console.log(value)
                if (value.length > 0) {
                    this.PersonInChargeImg = value[0].icon
                } else {
                    this.PersonInChargeImg = ''
                }
            },
            //参与人
            JoinUser(value) {
                if (value.length > 0) {
                    let self = this
                    this.$axios.post(
                        1047,
                        {
                            joinPerson: JSON.stringify(value),
                            CreateUserId: self.userinfo.uid,
                            CreateUser: self.userinfo.name,
                            plan_id: self.Id
                        },
                        res => {
                            if (res.data.code == 1) {
                                self.loadData()
                                this.$message.success(res.data.message)
                            } else {
                                this.$message.error(res.data.message)
                            }
                        }
                    )
                } else {
                    this.JoinPersoin = ''
                }
            },
            _hoverPerson(i) {
                this.PersonDelStatus = i
            },
            _hoverActive(i, type,e) {
                if(e.type=='click'){
                    console.log(222);
                }
                console.log(e);
                if (type == 1) {
                    // if(this.active2 ==i){
                    //     return false;
                    // }
                    this.active2 = i;
                    this.active = -1;
                } else {
                    // if(this.active == i){
                    //     return false;
                    // }
                    this.active = i ;
                    this.active2 = -1;
                }
                console.log(i, type);
            },
            _hoverHide() {
                console.log(222)
                this.active2 = -1
                this.active = -1
            },
            _onChangeIn(v){
                this.isIn = v;
            },
            _onChangeLookLive(v){
                this.isLookLive = v;
            },
            _onChangeLookBack(v){
                this.isLookBack = v;
            },
            emptyData(){
                this.searchInput = '';
                this.isIn = undefined;
                this.isLookLive = undefined;
                this.isLookBack = undefined;
            }
        }
    }
</script>
<style lang="less" scoped>
    .alert-video-shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.7;
        z-index: 1000;
    }

    .alert-video {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1100;
        video {
            width: 500px;
            height: 500px;
            outline: none;
        }
    }

    * {
        font-family: Microsoft YaHei;
    }

    .base-text {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .selectGather {
        border: 0 !important;
    }

    .total-people {
        height: 12px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
    }

    .warp-padding {
        padding: 27px 23px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        border-radius: 4px;
        margin-bottom: 20px;
        background: #ffffff;
        position: relative;
        /*background: red;*/
    }

    .upload-bth {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    .drawer-head {
        color: #333333;
        font-size: 16px;
        border-bottom: 1px solid #f3f3f3;
        height: 50px;
        line-height: 50px;
        margin-bottom: 20px;
    }

    .drawer-content-bottom {
        margin-bottom: 20px;
    }

    .drawer {
        height: 500px;
    }

    .drawer-bottom {
        width: 100%;
        border-top: 1px solid #f3f3f3;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 80px;
        line-height: 80px;
        padding: 15px 20px;
    }

    .coverImg {
        width: 520px;
        height: 292px;
        background: rgba(252, 252, 252, 1);
        border: 1px solid rgba(243, 243, 243, 1);
        position: relative;
        .cover-upload {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }

    .line-margin {
        margin-bottom: 25px;
    }

    .video {
        width: 162px;
        height: 91px;
        background: #fcfcfc;
        display: inline-block;
        margin-right: 10px;
        border: 1px solid #f3f3f3;
        text-align: center;
        cursor: pointer;
        position: relative;

        .video-title {
            color: rgba(51, 51, 51, 1);
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            text-align: left;
        }
        .video-bth {
            display: block;
            color: #9a9a9a;
            font-size: 40px;
            height: 50px;
        }
        .video-upload-time {
            /*width:102px;*/
            font-size: 12px;
            color: rgba(153, 153, 153, 1);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
        }
        video {
            width: 100%;
            height: 100%;
        }
        .shade {
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            width: 100%;
            height: 100%;
            opacity: 0.7;
        }
        .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px;
            color: #fff;
            z-index: 100;
        }
    }

    .coverRight {
        flex: 1;
        margin-left: 28px;
        min-width: 190px;
        .person-text {
            font-size: 14px;
            color: rgba(153, 153, 153, 1);
        }
        .person-avatar {
            margin-left: 12px;
            margin-right: 12px;
        }
    }

    .base-warp {
        height: 330px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        overflow-y: auto;
        padding: 20px 24px 27px 20px;
        box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
        .group {
            margin-bottom: 15px;
            margin-right: 25px;
            .group-label {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .group-label::after {
                display: inline-block;
                margin-left: 4px;
                color: #f5222d;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: '*';
            }
            .label-tips {
                font-size: 14px;
                color: rgba(153, 153, 153, 1);
                width: 70px;
                display: block;
                white-space: nowrap;
            }
            .join-name {
                width: 42px;
                text-align: center;
                color: rgba(153, 153, 153, 1);
                font-size: 14px;
                white-space: nowrap;
            }
        }
    }

    .tutor-text {
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
    }

    .tutor {
        max-width: 288px;
        display: inline-block;
        margin-right: 60px;
        margin-bottom: 33px;
        .tutor-info {
            margin-left: 20px;
            flex: 1;
            .tutor-name {
                font-size: 16px;
                color: rgba(51, 51, 51, 1);
                margin-bottom: 10px;
            }
            .tutor-info {
                font-size: 14px;
                color: rgba(102, 102, 102, 1);
            }
        }
    }

    .imsges {
        width: 157px;
        height: 88px;
        border: 1px solid rgba(243, 243, 243, 1);
        margin-bottom: 15px;
        margin-right: 15px;
        display: inline-block;
        img {
            width: 100%;
            height: 100%;
            display: inline-block;
        }
        .img-del {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(0, 0, 0, 0.4);
            height: 25px;
            line-height: 25px;
            width: 100%;
            text-align: center;
            color: #ffffff;
        }
    }

    .release {
        margin-right: 25px;
    }

    .search-flex {
        display: inline-block;
        width: 400px;
        height: 36px;
        line-height: 36px;
        margin-bottom: 14px;
        .search-list-item {
            margin-right: 28px;
            display: flex;
            .search-item-label {
                font-size: 14px;
                font-weight: bold;
                color: rgba(102, 102, 102, 1);
                margin-right: 10px;
                width: 60px;
                text-align: right;
            }
            .search-item-input {
                flex: 1;
            }
            .search-item-button {
                margin-right: 10px;
                height: 35px;
                line-height: 35px;
                width: 76px;
                text-align: center;
            }
        }
    }

    .upload-file-item {
        width: 100px;
        padding: 15px 10px;
        margin-right: 10px;
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        border-radius: 2px;
        display: inline-block;
        margin-bottom: 10px;
        position: relative;
        i {
            font-size: 28px;
            margin-bottom: 10px;
        }
        .del-file {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 10;
            i {
                font-size: 18px;
            }
        }
    }

    .Paper-bg {
        background: url('http://sk-ims-cabinet.sikegroup.com/FkZMrbRt2npUZSw3aPSjpdp7-Ezz') no-repeat !important;
        background-size: 100% 100% !important;
        width: 36px;
        height: 36px;
        display: block;
    }

    .quire-content {
        background: rgba(231, 232, 237, 1);
        border-radius: 5px;
        padding: 5px 20px;
        margin-bottom: 10px;
        display: inline-flex;
        width: 100%;
        .quire-icon {
            display: block;
            background: url('http://sk-ims-cabinet.sikegroup.com/FkKQKL4LdI-i6SJmGVd7-XD4FazH') no-repeat;
            background-size: 100% 100%;
            width: 36px;
            height: 36px;
        }

        .quire-title {
            font-size: 14px;
            color: rgba(51, 51, 51, 1);
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 25px;
            overflow: hidden;
            height: 36px;
            line-height: 36px;
            display: block;
            flex: 1;
        }
        .icon-shibai {
            display: block;
            height: 36px;
            line-height: 36px;
            width: 16px;
        }
    }

    .popup-warp {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 90;
        .popup-shadw {
            position: fixed;
            background: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 100;
        }
        .popup-content {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1000;
        }
        .popup-close {
            position: fixed;
            z-index: 10000;
            right: 1%;
            top: 22%;
            color: red;
            cursor: pointer;
            width: 50px;
            font-size: 32px;
        }
    }
</style>
